export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.gtranslate()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll) {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const $body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            $body.toggleClass('menu-open')

            // Close submenu
            $body.removeClass('submenu-open')
            $('.submenu').removeClass('active')
            $('.fullscreen-menu__item').removeClass('active')

            //Change logo
            if ($body.hasClass('menu-open')) {
                NavbarComponent.changeLogoColor('classic')
            } else {
                if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }

            // Open first submenu on desktop
            if (window.matchMedia('(min-width: 1000px)').matches && $body.hasClass('menu-open')) {
                $(submenu).first().trigger('click')
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static gtranslate() {
        const current_lang = '[data-id="wpiris_current-lang"]'
        const list_lang_item = '[data-id="wpiris_lang-list"] a'

        //Init select lang
        $(window).on('load', () => {
            setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')
                if (current_language === 'auto') {
                    current_language = 'FR'
                }
                $(current_lang).text(current_language)
                //Remove current language on list (init)
                $(list_lang_item).each(function () {
                    if ($(this).text() === current_language) {
                        $(this).hide()
                    }
                })
            }, 300)
        })

        //Change Header Language on click
        $(list_lang_item).on('click', () => {
            setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')
                if (current_language === 'auto') {
                    current_language = 'FR'
                }
                $(current_lang).text(current_language)
            }, 500)
        })
    }
}
