export default class ModalComponent {
    constructor() {
        ModalComponent.open()
        ModalComponent.close()
    }

    static open() {
        const trigger = '[data-trigger="customer-modal-open"]'

        $(trigger).on('click', function () {
            const element = $(this).attr('data-customer-modal')

            $(`[data-element="modal-${element}"]`).toggleClass('open')
            $('body').toggleClass('customer-modal-open')
        })

        // auto open when # is in URL
        const currentUrl = window.location.href
        if (currentUrl.endsWith('#modal-info-live') === true) {
            $('[data-customer-modal="info-live"]').first().trigger('click')
        }
    }

    static close() {
        const trigger = '[data-trigger="customer-modal-close"]'

        //On cross click or outside click
        $(trigger).on('click', () => {
            $('.customer-modal').removeClass('open')
            $('body').removeClass('customer-modal-open')
        })

        //On escape key
        $(document).on('keyup', (e) => {
            if (e.keyCode === 27) {
                $('.customer-modal').removeClass('open')
                $('body').removeClass('customer-modal-open')
            }
        })
    }
}
