import { Calendar } from '@fullcalendar/core'
import frLocale from '@fullcalendar/core/locales/fr'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import hc_tooltip from '@iris.interactive/handcook/public/scripts/components/tooltip/tooltip.component'
export default class CalendarComponent {
    /**
     * Header for the tooltip
     */
    static tooltipHeaders = {
        title: 'titre',
        start: 'début',
        end: 'fin',
        category: 'catégorie',
        participation: 'participation',
        status: 'status',
        sales_person: 'personne en charge',
        place: 'lieu',
    }

    /**
     * Color for events
     */
    static colors = {
        professional: '#FF0800',
        private: '#FDEE00',
        public: '#ED9121',
        confirmed: '#3B7B48',
        waiting: '#66C186',
    }

    /**
     * Winch data need to be displayed as list
     * @type {[string]}
     */
    static displayAsList = ['place']

    /**
     * Winch filter is active
     * @type {[]}
     */
    static activeFilter = []

    /**
     * Calendar entity
     * @private
     */
    _calendar

    /**
     * All event data in "cache"
     */
    static eventData

    /**
     * Element active for the calendar
     * @type {[]}
     */
    static activeElement = []

    /**
     * Construct
     */
    constructor() {
        CalendarComponent.init()
    }

    /**
     * Use to initialise the calendar
     */
    static init() {
        const calendarEl = document.getElementById('calendar')

        if (calendarEl) {
            const calendar = new Calendar(calendarEl, {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
                locales: [frLocale],
                locale: 'fr',
                eventDisplay: 'block',
                initialView: 'dayGridMonth',
                dayMaxEvents: true,
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,listWeek',
                },
                eventDidMount: (info) => {
                    info.el.id = info.event.id
                    hc_tooltip(`#${info.event.id}`, {
                        trigger: 'mouseenter focus',
                        content: CalendarComponent.getHTMLContent(info.event.extendedProps.data),
                        placement: 'auto',
                        allowHTML: true,
                        interactive: true,
                    })
                },
                events: [],
            })
            calendar.render()
            CalendarComponent.calendar = calendar

            CalendarComponent.fetchData().then((data) => {
                for (const event of data) {
                    event.id = `event-id-${crypto.randomUUID().toString()}`
                    CalendarComponent.addItem(event)
                }
                CalendarComponent.eventData = data
                CalendarComponent.initFilter()
            })
        }
    }

    /**
     * Use to add item in calendar
     * @param item
     */
    static addItem(item) {
        CalendarComponent.calendar.addEvent({
            id: item.id,
            title: item.NOM_EVT_ESPACE_PARTENAIRE,
            start: item.Début,
            end: item.Fin,
            className: CalendarComponent.getClassName(item.Catégorie),
            backgroundColor: CalendarComponent.getColor(item.Statut),

            data: {
                title: item.NOM_EVT_ESPACE_PARTENAIRE,
                start: item.Début,
                end: item.Fin,
                category: item.Catégorie,
                participation: item.Participation_prévisionnelle,
                status: item.Statut,
                sales_person: item.SalesPerson,
                place: item.Réservations,
            },
        })
        CalendarComponent.activeElement.push(item.id)
    }

    /**
     * Get the color the event type
     * @param type
     * @returns {string}
     */
    static getColor(type) {
        switch (type) {
            case 'Confirmé':
                return CalendarComponent.colors.confirmed
            default:
                return CalendarComponent.colors.waiting
        }
    }

    static getClassName(type) {
        switch (type) {
            case 'Manifestation Professionnelle':
                return 'event-professional'
            case 'Manifestation Privée':
                return 'event-private'
            default:
                return 'event-public'
        }
    }

    /**
     * Get the content as HTML for the tooltip
     * @param contentProperty
     * @returns {*}
     */
    static getHTMLContent(contentProperty) {
        const parentDiv = document.createElement('div')
        parentDiv.classList.add('flex', 'flex-col', 'w-max')

        const headerKeys = Object.keys(CalendarComponent.tooltipHeaders)
        const filtered = headerKeys.filter((key) => !CalendarComponent.displayAsList.includes(key))

        for (const header of filtered) {
            const headerDiv = document.createElement('div')
            headerDiv.classList.add('mt-2')
            const spanTitle = document.createElement('span')
            spanTitle.classList.add('uppercase', 'text-bold', 'underline')
            spanTitle.innerHTML = `${CalendarComponent.tooltipHeaders[header]}:`

            headerDiv.appendChild(spanTitle)

            const spanTitleContent = document.createElement('span')
            spanTitleContent.innerHTML = `&nbsp;${contentProperty[header]}`
            headerDiv.appendChild(spanTitleContent)

            parentDiv.appendChild(headerDiv)
        }

        for (const listKey of CalendarComponent.displayAsList) {
            const listDivContainer = document.createElement('div')
            listDivContainer.classList.add('flex', 'flex-col')

            const spanTitle = document.createElement('span')
            spanTitle.classList.add('uppercase', 'text-bold', 'underline')
            spanTitle.innerHTML = `${CalendarComponent.tooltipHeaders[listKey]}:`

            listDivContainer.appendChild(spanTitle)

            const list = document.createElement('ul')

            for (const place of contentProperty.place) {
                const listElement = document.createElement('li')
                listElement.innerHTML = `&nbsp;${place.placeName},${place.placeDescription}`
                list.appendChild(listElement)
            }

            listDivContainer.appendChild(list)
            parentDiv.appendChild(listDivContainer)
        }

        return parentDiv
    }

    /**
     * Fetch event data
     * @returns {Promise<any>|{}}
     */
    static fetchData() {
        try {
            return fetch(IRISCollectionCustomer.irisSEOFeedData, {
                method: 'GET',
                headers: {
                    'X-Wp-Nonce': IRISCollectionCustomer.eventDataNonce,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    return data.data
                })
        } catch (e) {
            return {}
        }
    }

    /**
     * Initialize filters
     * @returns {Promise<void>}
     */
    static async initFilter() {
        const filterCheckbox = document.querySelectorAll('[data-target-filter=true]')
        for (const checkbox of filterCheckbox) {
            checkbox.addEventListener('change', (e) => {
                const filterType = checkbox.getAttribute('data-filter-type')
                CalendarComponent.applyFilter(filterType)
            })
        }
    }

    /**
     * Apply a filter
     * @param filter
     * @returns {Promise<void>}
     */
    static async applyFilter(filter) {
        if (CalendarComponent.activeFilter.includes(filter)) {
            CalendarComponent.activeFilter = CalendarComponent.activeFilter.filter(
                (current) => current !== filter,
            )
        } else {
            CalendarComponent.activeFilter.push(filter)
        }

        let eventConfirmationFilter = CalendarComponent.eventData
        let eventFiltered = []

        if (
            CalendarComponent.activeFilter.includes('confirmed') &&
            !CalendarComponent.activeFilter.includes('non-confirmed')
        ) {
            eventConfirmationFilter = CalendarComponent.eventData.filter(
                (element) => element.Statut === 'Confirmé',
            )
        } else if (
            CalendarComponent.activeFilter.includes('non-confirmed') &&
            !CalendarComponent.activeFilter.includes('confirmed')
        ) {
            eventConfirmationFilter = CalendarComponent.eventData.filter(
                (element) => element.Statut === 'Arrhes en attente',
            )
        }

        for (const activeFilter of CalendarComponent.activeFilter) {
            switch (activeFilter) {
                case 'professional':
                    eventFiltered = eventFiltered.concat(
                        eventConfirmationFilter.filter(
                            (element) => element.Catégorie === 'Manifestation Professionnelle',
                        ),
                    )
                    break
                case 'private':
                    eventFiltered = eventFiltered.concat(
                        eventConfirmationFilter.filter(
                            (element) => element.Catégorie === 'Manifestation Privée',
                        ),
                    )
                    break
                case 'public':
                    eventFiltered = eventFiltered.concat(
                        eventConfirmationFilter.filter(
                            (element) => element.Catégorie === 'Manifestation Publique',
                        ),
                    )
                    break
            }
        }

        CalendarComponent.calendar.removeAllEvents()

        if (eventFiltered.length > 0) {
            for (const event of eventFiltered) {
                if (CalendarComponent.calendar.getEventById(event.id.toString()) === null) {
                    CalendarComponent.addItem(event)
                }
            }
        } else if (eventConfirmationFilter.length > 0) {
            for (const event of eventConfirmationFilter) {
                if (CalendarComponent.calendar.getEventById(event.id.toString()) === null) {
                    CalendarComponent.addItem(event)
                }
            }
        } else {
            for (const event of CalendarComponent.eventData) {
                if (CalendarComponent.calendar.getEventById(event.id.toString()) === null) {
                    CalendarComponent.addItem(event)
                }
            }
        }
    }
}
